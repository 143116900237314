export const generalStructure = [
    {
      "name": "",
      "percentage": "",
      "item": "",
      "date": "",
      "company": "",
      "institute": "",
      "location": "",
      "singlePostText": "",
      "url": "",
      "highlighted": false,
      "image": {
        "title": "",
        "description": "",
        "contentType": "",
        "fileName": "",
        "size": 1,
        "url": "",
        "width": 1,
        "height": 1
      }
    }
  ]