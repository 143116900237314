export const queryResume = `query{
  generalInformationInTheResumeCollection(
    order:[date_DESC]
  ){
    items{
      name
      date
      company
      location
      singlePostText
      url
      image {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      highlighted
    }
  }
  informationAboutStudiesInTheCurriculumCollection(
    order:[date_DESC]
  ){
    items{
      name
      date
      institute
      location
      singlePostText
      url
      image {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      highlighted
    }
  }
  informationAboutLicensesAndCertificationsCollection(
    order:[date_DESC]
  ){
    items{
      name
      date
      institute
      location
      singlePostText
      url
      image {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      highlighted
    }
  }
  vtexTrainingCollection(
    order:[date_DESC]
  ){
    items{
      name
      date
      institute
      location
      singlePostText
      url
      image {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      highlighted
    }
  }
  skillsGlobesCollection(
    order:[name_DESC]
  ){
    items{
      name
      percentage
    }
  }
  knowledgeCollection(
    order:[item_DESC]
  ){
    items{
      item
    }
  }
}`