import "./styles/ShowCredentialButton.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    text?: string;
    href: string;
    blank?: boolean;
    icon?: any;
}

const ShowCredentialButton: React.FC<Props> = ({
    text = "Show Credential",
    href = "#",
    blank = false,
    icon = null
}) => {
    return (
        <div className="show-credential-button">
            <a href={href}
                target={blank ? "_blank" : ""}
                title={text}
                rel={blank ? "noreferrer" : ""}>
                <span className="text">{text}</span>
                <span className="icon">
                    {icon ? <FontAwesomeIcon icon={icon} /> : null}
                </span>
            </a>
        </div>
    );
}

export default ShowCredentialButton;
