import "./styles/ItemList.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    text?: string;  // Optional prop
    icon?: any;     // Optional prop
}

const ItemList = ({ text = "", icon = null }: Props) => {
    return (
        <h6 className="item-list">
            <span className="icon">
                {icon ? <FontAwesomeIcon icon={icon} /> : null}
            </span>
            {text}
        </h6>
    );
};

export default ItemList;
