import React, { useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import "./styles/Skills.css"

ChartJS.register(ArcElement, Tooltip, Legend);
interface SkillData {
    skill: string;
    data: number[];
    backgroundColor: string[];
}

interface SkillsProps {
    skillsData: SkillData[];
}

const Skills: React.FC<SkillsProps> = ({ skillsData }) => {
    const [isVisible, setIsVisible] = useState(false);
    const divRef = useRef<HTMLDivElement>(null); // Specify the type of ref here

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {
                threshold: 0.1, // Adjust the threshold as needed
            }
        );

        const currentDivRef = divRef.current;

        if (currentDivRef) {
            observer.observe(currentDivRef);
        }

        return () => {
            if (currentDivRef) {
                observer.unobserve(currentDivRef);
            }
        };
    }, []);

    return (
        <div className="charts-container" ref={divRef}>
            {isVisible && skillsData.map((skill:any, index:number) => (
                <div className="chart-item" key={index}>
                    <Doughnut
                        data={{
                            labels: [`${skill.skill}`, ''],
                            datasets: [{
                                data: skill.data,
                                backgroundColor: skill.backgroundColor,
                                borderWidth: 1,
                            }],
                        }}
                        options={{
                            animation: {
                                animateRotate: true
                            },
                            interaction: {
                                intersect: false
                            },
                            plugins: {
                                legend: {
                                    display: false,
                                }
                            }
                        }}
                    />
                    <h6>{skill.skill}</h6>
                    <div className='percentage'>
                        <span>
                            {skill.data[0]}%
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Skills;
