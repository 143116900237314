import "./styles/customButtons.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    text?: string
    href?: string
    blank?: boolean
    icon?: any
}

const CustomButton: React.FC<Props> = ({
    text = "Custom Button",
    href = "#",
    blank = false,
    icon = null
}) => {
    return (
        <div className="custom-button">
            <a href={href}
                target={blank ? "_blank" : ""}
                title={text}
                rel={blank ? "noreferrer" : ""}>
                <span className="text">{text}</span>
                <span className="icon">
                    {icon ? <FontAwesomeIcon icon={icon} /> : <></>}
                </span>
            </a>
        </div>
    )
}

export default CustomButton
