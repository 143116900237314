import React, { useState, FormEvent } from 'react';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'; // Import the green checkmark icon
import './styles/ContactForm.css';

const ContactForm: React.FC = () => {
    const [showMeetingDiv, setShowMeetingDiv] = useState(false);
    const [formStatus, setFormStatus] = useState('');
    const [formSent, setFormSent] = useState(false); // Add state to control form visibility

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Send email using EmailJS
        emailjs.sendForm('service_u3gjznk', 'template_heixcjn', e.currentTarget, 'nqi-oQUs9lbSpXRfg')
            .then((result) => {
                setFormStatus('Email sent successfully!');
                setFormSent(true); // Set formSent to true
            }, (error) => {
                setFormStatus('Error sending email');
            });
    };

    const toggleMeetingDiv = () => {
        setShowMeetingDiv((prevShow) => {
            const newShow = !prevShow;
            if (newShow) {
                setTimeout(() => {
                    const meetingDiv = document.querySelector('.meeting-div');
                    if (meetingDiv) {
                        meetingDiv.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 500);
            }
            return newShow;
        });
    };

    return (
        <div className="contact-form-container">
            <h1>Contact Me</h1>
            <h2>Feel free to reach out for any inquiries or questions.</h2>

            {!formSent ? ( // Conditionally render the form
                <form onSubmit={handleSubmit} className="contact-form">
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input type="text" id="name" name="name" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message:</label>
                        <textarea id="message" name="message" required></textarea>
                    </div>
                    <button type="submit" className="submit-button">Send Message</button>
                    {formStatus && <p>{formStatus}</p>}
                </form>
            ) : (
                <div className="confirmation-message">
                    <div className="check-icon-wrapper">
                        <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                    </div>
                    <div className="confirmation-text">
                        <p>{formStatus}</p>
                    </div>
                </div>
            )}

            <button onClick={toggleMeetingDiv} className="toggle-button">
                Select a day to get a meeting with me
            </button>
            
            <div
                className={`meeting-div ${showMeetingDiv ? 'show' : 'hide'}`}
            >
                {/* Calendly Inline Widget */}
                <div
                    className="calendly-inline-widget"
                    data-url="https://calendly.com/serbeldiaz/30min?hide_event_type_details=1&hide_gdpr_banner=1&background_color=222222&text_color=ffffff"
                    style={{ minWidth: '300px', width: '85vw', maxWidth: '550px', height: '600px' }}
                ></div>
            </div>
        </div>
    );
};

export default ContactForm;
