
import { bearerContentful, contentfulSpace } from "./env";

type Props = {
    query: string
}

export async function getDataFromContentful(
    { query }: Props,
) {
    try {
        if (query) {
            let reqParams: string = JSON.stringify({
                query: query.replace(/'/g, '"')
            });

            if (reqParams) {
                const response = await fetch(
                    `https://graphql.contentful.com/content/v1/spaces/${contentfulSpace}/environments/master`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${bearerContentful}`,
                            'Content-Type': 'application/json',
                        },
                        body: reqParams,
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();

                return {
                    data: data.data
                };
            } else {
                return {
                    status: 'Error',
                    message: 'Failed to fetch data from Contentful: Invalid query parameter'
                };
            }
        } else {
            return {
                status: 'Error',
                message: 'Failed to fetch data from Contentful: Missing query parameter'
            };
        }
    } catch (error: any) {
        return {
            status: 'Error',
            message: 'Failed to fetch data from Contentful',
            body: error.message
        };
    }
}
