import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ContactForm from '../components/organisms/ContactForm';

const CalendlyWidget = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <Helmet>

                <link rel="canonical" href="https://www.serbeld.space" />

                <title>Contact Me | Schedule a Meeting with Sergio Beleño on Calendly</title>
                <meta name="description" content="Get in touch with Sergio Beleño effortlessly through Calendly. Schedule a meeting, ask questions, or connect for collaborations. Use our simple and convenient scheduling tool to find a time that works for you." />

                <meta property="og:locale" content="en_CO" />
                <meta property="og:title" content="Contact Me | Schedule a Meeting with Sergio Beleño on Calendly" />
                <meta property="og:description" content="Get in touch with Sergio Beleño effortlessly through Calendly. Schedule a meeting, ask questions, or connect for collaborations. Use our simple and convenient scheduling tool to find a time that works for you." />
                <meta property="og:url" content="https://www.serbeld.space" />
                <meta property="og:site_name" content="Contact Me | Schedule a Meeting with Sergio Beleño on Calendly" />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@serbeld" />
                <meta name="twitter:title" content="Contact Me | Schedule a Meeting with Sergio Beleño on Calendly" />
                <meta name="twitter:description" content="Get in touch with Sergio Beleño effortlessly through Calendly. Schedule a meeting, ask questions, or connect for collaborations. Use our simple and convenient scheduling tool to find a time that works for you." />
                <meta name="twitter:url" content="https://www.serbeld.space" />
            </Helmet>


                <div className="card-wrap">
                    <div className="content">
                        <ContactForm />
                    </div>
                </div>
        </>
    );
};

export default CalendlyWidget;
